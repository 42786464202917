import React from "react"
import styled from "styled-components"
import Link from "gatsby-link"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import CaseSingleStyled from "../components/styles/CaseSingleStyles"
import Builder from "../components/builder"
import FeedLastCaseStudy from "../components/feedLastCaseStudy"
import back from "../../static/back.png"
import SEO from "../components/seo"
import myInitObject from "../ultis/variable"
import HalfForm from "../components/half/halfForm"
import HalfImage from "../components/half/halfImage"
import LogoSlider from "../components/logoCase"

const StyledSection = styled.section`
  margin-bottom: 60px;
`

const StyledHalf = styled.section`
  padding-bottom: 0px;
`

const CaseStudyTemplate = ({ data, pageContext }) => {
  const tmp = "/casestudies/" + pageContext.slug
  const pageContextTmp = { ...pageContext, slug: tmp }
  const {
    slug,
    content,
    metaTitle,
    metaDescription,
    metaImage,
    _id,
  } = data.swapi.caseStudyCollection[0]
  const { Form: studyForm } = data.swapi.caseStudySingleton
  return (
    <Layout pageContext={pageContextTmp}>
      <SEO
        title={metaTitle}
        description={metaDescription}
        img={metaImage ? metaImage.path : ""}
        lang="pl"
      />
      <CaseSingleStyled>
        <div className="container">
          <div className="back">
            <Link to="/casestudies">
              <img src={back} alt="back" />
              Lista Case Studies
            </Link>
          </div>
        </div>
        <Builder data={content} pageContext={pageContext} />
      </CaseSingleStyled>
      <StyledHalf className="half">
        <HalfForm
          position="left"
          backgroundColor="#000"
          heading={"Heading"}
          headingColor="#fff"
          form={studyForm}
        />
        <HalfImage
          backgroundImage={myInitObject.cockpitUrl + studyForm.img.path}
        />
      </StyledHalf>
      <StyledSection>
        <LogoSlider></LogoSlider>
      </StyledSection>
      <div className="container">
        <FeedLastCaseStudy pastPost={_id} heading="Zobacz inne Case Studies" />
      </div>
    </Layout>
  )
}

export default CaseStudyTemplate

export const query = graphql`
  query CaseStudyTemplateQuery($id: String!) {
    swapi {
      caseStudyCollection(_id: $id) {
        _id
        slug
        metaTitle
        metaDescription
        metaImage {
          path
        }
        content
      }
      caseStudySingleton(lang: "pl") {
        Form {
          buttonText
          checkboxError
          checkboxText
          heading
          subheading
          img {
            path
          }
          placeholderEmail
          placeholderName
          placeholderPhone
          emailError
          nameError
          phoneError
          thx
        }
      }
    }
  }
`
